<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>  
        <div class="rb1">
          <img src="@/assets/tj_1.png" class="icon" />
          <div class="text" @click="next">提交</div>
        </div>
        <div class="rb" @click="onTjtxr">
          <img src="@/assets/add.png" class="icon" />
          <div class="text">添加</div>
        </div> 
        <!--<div class="rb1" @click="onTjtxr()" >
          <img src="@/assets/add.png" class="icon" />
          <div class="text">添加同行人</div>
        </div> -->
      </div>
      <div class="content">
        <div class="items">
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box yy2" @click="onDxtx(item)">
              <div class="name dx">
                <img src="@/assets/dx1.png" v-if="item.dxflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="item.dxflag === false" class="dxicon" />
              </div>             
            </div>
            <div class="box zz" @click="onDxtx(item)">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>              
              <div class="name">
                <span class="region">电话：{{ item.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">证件：{{ item.certificate_type }}</span>
              </div>
            </div>
            <div class="box yy3" @click="onDxtx(item)">
              <div class="name t1">                              
                <div v-if="item.state === '0' " class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.state === '1' " class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <!--<div class="box yy1" @click="onDxtx(item)">
              <div class="name">                              
                <img :src="item.picture" class="imgpic" />
              </div>              
            </div>-->           
          </div>
        </div>
      </div>
      <!--<div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">下一步</div>         
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    
    let openid = get(this.$route, 'query.openId');
    if (openid !== null && openid !== undefined && openid.length !== 0) {
      window.sessionStorage.setItem('openid', openid);
    }

    this.SiteCode = get(this.$route, 'query.SiteCode');
    this.ClientID = get(this.$route, 'query.ClientID');
    this.query();

    this.hrefurl = location.href;
    window.sessionStorage.setItem('hrefurl', this.hrefurl);
  },
  data() {
    return {
      integral: 0,
      selectItem: '1',
      selectQyItem: '3',
      roomname: '503',
      region: 'C区',
      floor: '5',
      enterdate: '2020-01-02',
      leavedate: '2020-01-05',
      days: '3',   
      ClientID:'', 
      SiteCode:'',
      IdNum:'',
      hrefurl:'',
      listidnum:'',
      items: [
        
      ],
      nextitems: [
        
      ]
    };
  },
  methods: {
    async onDxtx(item) {
      if(item.state == '0'){
        alert("扫码入住的不能取消!");
        return;
      }
      //alert(item.certificate_code);
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_usertype',
        qs.stringify({
          idnum: item.certificate_code
        })
      );
      if (data.flag === '0') {
        item.dxflag=!item.dxflag; 
      }else{
        Dialog({ message: "此人已经入住！" });
      }
      

    },
    async query() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_selectpeers');
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
      
    },
    async onTjtxr() {
        this.$router.replace({path: '/tourist/checkin_travelers_add', query: {SiteCode:this.SiteCode,ClientID:this.ClientID}});
    },
    async next() {
      //this.nextitems = [];
      this.listidnum = '';
      var fflag = true;
      for(var j = 0; j < this.items.length; j++) {
        if(this.items[j].dxflag === true){
            //this.nextitems.push(this.items[j]); 
            if(fflag){
              this.listidnum = this.items[j].certificate_code;    
            }else{
              this.listidnum = this.listidnum + ',' + this.items[j].certificate_code;    
            }
            fflag = false;     
            if(this.items[j].state === '0'){
              this.IdNum = this.items[j].certificate_code;
            }
        }
      } 
      //this.$router.push({path: '/tourist/peers_photo_1', query: {nextitems: this.nextitems,SiteCode:this.SiteCode,ClientID:this.ClientID,IdNum:this.IdNum}});
      this.$router.push({path: '/tourist/peers_photo_1', query: {listidnum: this.listidnum,SiteCode:this.SiteCode,ClientID:this.ClientID,IdNum:this.IdNum}});
    }   
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    //width: 236px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 49%;
    }
    &.yy {
      width: 11%;
    }
    &.yy2 {
      width: 15%;     
    }
    &.yy1 {
      width: 25%;
    }
    &.yy3 {
      width: 36%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 0px;
      }
      &.t1 {
        margin-top: -4px;
      }
      &.dx {
        margin-top: 50px;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h3 {
          margin-right: 35px;
        }
      }
      .imgpic {
        display: block;
        width: 114px;   
        height: 160px;  
        float: right;
      }
      .dxicon {
        width: 39px;
        height: 39px;
        vertical-align: middle;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 55px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
</style>
